import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { BookDefinition, BookRenderData } from '@/components/common/Book';
import { useSectionTrackingDataContext } from '@/components/genreHome/common/SectionTrackingDataContextProvider';
import { SectionByLayout } from '@/features/genreHome/views/utils/viewDataUtils';
import { fetchSectionItemsResourceAction, reduceSectionItemsAction } from '@/features/genreHome/views/viewsSlice';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useIsomorphicLayoutEffect } from '@/hooks/useIsomorphicLayoutEffect';
import { GroupFetchUrl, GroupUnit, SectionLayoutType } from '@/models/backendsApi/v2/Views/ViewsType';

import { GenreHomeBook } from '../../common/GenreHomeBook';
import { SectionLayout } from '../../common/SectionLayout';
import { GroupList, GroupListScrollContainerController } from './GroupList';
import { GroupTabs } from './GroupTabs';
import { GroupBookPreset } from './presets';
import {
  deleteGroupSectionTabInfo,
  getSavedGroupSectionTabInfoMap,
  SavedGroupSectionTabInfo,
  saveGroupSectionTabInfo,
} from './utils';
import { getMoreDetailLink } from './utils/getMoreDetailLink';

export interface GroupProps {
  section: SectionByLayout<SectionLayoutType.Group>;
}

/**
 * 현재 그룹 섹션이 제공하는 형태는 두 가지
 * 1. 요일연재 섹션
 * 2. (실험용)키워드 섹션
 *
 * TODO: 실험용 키워드 섹션이 정규 섹션이 되면 탭 복구와 관련된 로직을 리팩토링 해야함.
 */
export const Group = ({ section }: GroupProps): ReactJSX.Element => {
  const dispatch = useAppDispatch();
  const storageType = section.type === 'Keyword' ? 'local' : 'session';
  const sectionTrackingData = useSectionTrackingDataContext();

  const groupTabItems: GroupUnit[] = section.groups.map(group => group.group_unit);
  const [selectedGroupTabItem, setSelectedGroupTabItem] = useState<GroupUnit | null>(null);

  const selectedGroupItems = useMemo(
    () => (selectedGroupTabItem ? section.items[selectedGroupTabItem.id] : undefined),
    [selectedGroupTabItem, section.items],
  );

  const hasSelectedGroupItems = !!selectedGroupItems;

  /*
   * References
   */
  const groupListContainerControllerRef = useRef<GroupListScrollContainerController>(null);
  const selectedGroupTabItemRef = useRef<GroupUnit | null>(selectedGroupTabItem);
  const selectedGroupItemsRef = useRef<BookRenderData[]>([]);

  if (selectedGroupTabItem) {
    selectedGroupTabItemRef.current = selectedGroupTabItem;
    selectedGroupItemsRef.current = selectedGroupItems ?? [];
  }

  /**
   * 뒤로가기로 페이지에 돌아왔을 때 탭 복구를 위한 effect hook. `요일연재 섹션에만 해당(키워드 섹션 X)`
   *
   * 뒤로가기와 관련된 브라우저 환경은 아래 두가지이다.
   *   1. 뒤로가기 시 bfcache를 사용하지 않는 브라우저의 경우
   *   2. 뒤로가기 시 bfcache를 사용하는 브라우저의 경우(restoreOrResetTabInfo)
   * 뒤로가기시 탭 복구 조건은 아래의 두 가지이다.
   *   a. 요인연재 섹션 내부 클릭으로 페이지 이탈 후 뒤로가기 시 => 탭 복구
   *   b. 요인연재 섹션 외부 클릭으로 페이지 이탈 후 뒤로가기 시 => 탭 미복구(탭 리셋)
   *
   * 1-a의 경우 PerformanceNavigationTiming API를 활용하여 뒤로가기 여부를 판별해 sessionStorage에서 저장한 탭을 불러온다. 그 후 sessionStorage를 리셋한다.
   * 1-b의 경우 복구하지 않아도 되므로 sessionStorage를 리셋한다.
   * 2-a의 경우 bfcache를 통해 이미 탭이 복구되어 있으므로 sessionStorage만 리셋한다.
   * 2-b의 경우 bfcache를 무효화해 탭을 리셋한 후 sessionStorage를 리셋한다.
   */

  /*
   * Scroll Restoration
   */
  const [scrollRestoration, setScrollRestoration] = useState<number | null>(null);
  useIsomorphicLayoutEffect(() => {
    if (scrollRestoration === null) {
      return undefined;
    }

    const scroller = groupListContainerControllerRef.current?.getScroller();
    if (!scroller) {
      return undefined;
    }

    if (!hasSelectedGroupItems) {
      return undefined;
    }

    let rafId: number | null = requestAnimationFrame(() => {
      scroller.scrollLeft = scrollRestoration;
      setScrollRestoration(null);
      rafId = null;
    });

    return () => (rafId ? cancelAnimationFrame(rafId) : undefined);
  }, [scrollRestoration, hasSelectedGroupItems]);

  /*
   * Fetch Section
   */
  const fetchUrlByUnitId = useMemo(
    () =>
      section.groups.reduce<Record<number, GroupFetchUrl>>(
        (map, group) => ({ ...map, [group.group_unit.id]: group.fetch_url }),
        {},
      ),
    [section.groups],
  );

  useEffect(() => {
    const unitId = selectedGroupTabItem?.id;
    if (!unitId) {
      return;
    }

    if (hasSelectedGroupItems) {
      return;
    }

    const endPoint = fetchUrlByUnitId[unitId]?.adult_include;
    if (!endPoint) {
      return;
    }

    dispatch(
      fetchSectionItemsResourceAction({
        reqParams: { sectionId: section.id, endPoint, payload: { unitId } },
      }),
    );
  }, [dispatch, section.id, selectedGroupTabItem?.id, hasSelectedGroupItems, fetchUrlByUnitId]);

  /*
   * Initialization
   */
  const [savedTabInfoMap] = useState(() => getSavedGroupSectionTabInfoMap(storageType));
  const savedTabInfo = useMemo(() => savedTabInfoMap[section.id], [savedTabInfoMap, section.id]);
  useEffect(() => {
    if (section.type === 'Keyword') {
      return;
    }
    deleteGroupSectionTabInfo(storageType, section.id);
  }, [storageType, section.id, section.type]);

  const getDefaultGroup = useCallback(() => {
    const activeUnit = section.groups.find(group => group.group_unit.id === section.current_active_group_unit.id);
    return (activeUnit || section.groups[0]).group_unit;
  }, [section.groups, section.current_active_group_unit.id]);

  /*
   * Initialization
   * > Case 1: Not Using BFCache
   */
  useIsomorphicLayoutEffect(() => {
    const isBackOrForward =
      (performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming)?.type === 'back_forward';

    if (savedTabInfo && section.type === 'Keyword') {
      if (groupTabItems.some(groupTabItem => groupTabItem.id === savedTabInfo.tab.id)) {
        setSelectedGroupTabItem(savedTabInfo.tab);
      } else {
        deleteGroupSectionTabInfo(storageType, section.id);
        setSelectedGroupTabItem(getDefaultGroup());
      }
      return;
    }

    if (savedTabInfo && isBackOrForward) {
      dispatch(
        reduceSectionItemsAction({
          sectionId: section.id,
          payload: { unitId: savedTabInfo.tab.id },
          resource: { groupSavedItems: savedTabInfo.items },
        }),
      );
      setScrollRestoration(savedTabInfo.scrollPosition);
      setSelectedGroupTabItem(savedTabInfo.tab);
      return;
    }

    setSelectedGroupTabItem(getDefaultGroup());
  }, [dispatch, getDefaultGroup, savedTabInfo]);

  /*
   * Initialization
   * > Case 2: Using BFCache
   */
  useIsomorphicLayoutEffect(() => {
    const restoreOrResetTabInfo = (pageShowEvent: PageTransitionEvent) => {
      if (!pageShowEvent.persisted) {
        return;
      }

      if (section.type === 'Keyword') {
        return;
      }

      const uncommittedSavedTabInfo = getSavedGroupSectionTabInfoMap(storageType)[section.id];
      if (uncommittedSavedTabInfo) {
        deleteGroupSectionTabInfo(storageType, section.id);
        return;
      }

      const currentActiveTab = section.current_active_group_unit;
      if (currentActiveTab.id === selectedGroupTabItemRef.current?.id) {
        setScrollRestoration(0);
      }
    };

    window.addEventListener('pageshow', restoreOrResetTabInfo);
    return () => window.removeEventListener('pageshow', restoreOrResetTabInfo);
  }, [section.id, section.current_active_group_unit]);

  /*
   * Rendering
   */
  const saveTabInfo = useCallback(() => {
    saveGroupSectionTabInfo(storageType, {
      sectionId: section.id,
      tab: selectedGroupTabItemRef.current,
      items: selectedGroupItemsRef.current,
      scrollPosition: groupListContainerControllerRef.current?.getScroller()?.scrollLeft || 0,
    } as SavedGroupSectionTabInfo);
  }, [storageType, section.id]);

  const groupBookPreset = useMemo(
    () => GroupBookPreset({ onBeforeNavigate: section.type === 'Keyword' ? () => {} : saveTabInfo }),
    [saveTabInfo, section.type],
  );
  const renderItem = useCallback(
    ({ item, index }: { item: BookRenderData; index: number }) => <GenreHomeBook book={item} index={index} />,
    [],
  );

  const moreDetailLink = getMoreDetailLink({
    section,
    groupUnitId: selectedGroupTabItem ? selectedGroupTabItem.id : section.current_active_group_unit.id,
  });

  const onSelectedTabChange = useCallback(
    (groupUnit: GroupUnit) => {
      if (section.type === 'Keyword') {
        saveGroupSectionTabInfo(storageType, {
          sectionId: section.id,
          tab: groupUnit,
          items: [],
          scrollPosition: 0,
        });
      }
      setSelectedGroupTabItem(groupUnit);
    },
    [storageType, section],
  );

  return (
    <BookDefinition presets={groupBookPreset} trackingData={sectionTrackingData.bookDefinition}>
      <SectionLayout title={section.title} link={moreDetailLink} paddingBottom={false} onLinkClick={saveTabInfo}>
        <GroupTabs
          type={section.type}
          selectedItem={selectedGroupTabItem ?? undefined}
          items={groupTabItems}
          onChange={onSelectedTabChange}
          sectionTrackingData={sectionTrackingData}
        />
        <GroupList
          items={section.items}
          renderItem={renderItem}
          selectedGroupUnitId={selectedGroupTabItem?.id ?? undefined}
          ref={groupListContainerControllerRef}
        />
      </SectionLayout>
    </BookDefinition>
  );
};
